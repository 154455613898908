<template>
  <main-frame component="NotFound">
    <!-- <div class="error-page-content" slot="error-page">
      <img class="error-img" src="../../assets/404.png" alt />
      <p class="p2">抱歉，您访问的页面不存在</p>
    </div>-->
    <div class="error-page-content" slot="error-page">
      <img class="error_shade" src="../../assets/new_ui/play_shade.png" alt="">
      <div class="shade_box">
        <img class="error-img" src="../../assets/new_ui/404.png" alt="">
        <div class="text_box">
          <p class="p2">页面好像走丢了！</p>
          <p class="p2">请再试一下</p>
          <a href="javascript:void(0)" class="reload" @click="_reload()">刷新重试</a>
        </div>
      </div>
    </div>
  </main-frame>
</template>
<script>
import MainFrame from "views/home/index.vue";
export default {
  name: "NotFoundComponent",
  components: {
    MainFrame
  },
  data() {
    return {
      time: 5
    };
  },
  methods: {
    _reload() {
      location.reload();
    }
  },

  created() {}
};
</script>
<style lang="less">
</style>

